import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useWorkData} from "../../hooks/useWorkData";
import {Box, Button, Columns, Form, Modal, Pagination, Section, Table} from "react-bulma-components";
import {getCostCentresData, getDataFresh, getDepartmentsData, getWorkData, getWorkTypeData} from "../../state/selectors";
import {InvoiceRows, PORows} from "./TableRows";
import {createSetDataFreshAction, createSetWorkDataAction} from "../../actions/dataActions";
import axios from "axios";
import {FiguresSection} from "./FiguresSection";
import ReactToPrint from "react-to-print";
import PrintPO from "./PrintPO";
import PrintInvoice from "./PrintInvoice";
import {NotificationManager} from "react-notifications";
import FilterHeading from "./FilterHeading";
import Spinner from "react-spinner-material";
import useSelect from "../../hooks/useSelect";
import FilterBlankHeading from "./FilterBlankHeading";
import FAIcon from "../Icon/FAIcon";
import MultipleEditModalWorkshop from "./MultipleEditModalWorkshop";
import {
  updateBothInvoices,
  updateCustomerInvoice,
  updateHireCustomerInvoice,
  updateWorkshopInvoice
} from "./updateInvoice";
import MultipleEditModalCustomer from "./MultipleEditModalCustomer";
import {useDepartmentsData} from "../../hooks/useDepartmentsData";
import DepartmentPicker from "../Job/DepartmentPicker";
import useInput from "../../hooks/useInput";
import {validateBeforeApproval} from "../../utils";
import {useWorkTypeData} from "../../hooks/useWorkTypeData";
import SearchBar from "../Utils/SearchBar";
import StateChangeModal from "./StateChangeModal";
import LoadingModal from "../Utils/LoadingModal";

function InvoicePO(props) {
  const dispatch = useDispatch();
  const componentRef = useRef();
  const [searchText, setSearchText] = useState("");
  const [currentSearch, setCurrentSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [printDate, setPrintDate] = useState(new Date().toISOString());
  const [searchFresh, setSearchFresh] = useState(1);
  const [stateModalOpen, setStateModalOpen] = useState(false);
  const [selectedWork, setSelectedWork] = useState();
  const [filteredWork, setFilteredWork] = useState({results: []});
  const [loadingModal, setLoadingModal] = useState(false);
  const [multiStateChange, setMultiStateChange] = useState(false);

  const [filters, setFilters] = useState({});

  const [selected, setSelected] = useState({});

  useEffect(() => {
    getFilteredWork(props.view)
  }, [selectedWork]);


  let [start, startInput, setStart] = useInput({
    type: "date",
    label: "Start Date",
    initialValue: undefined
  })

  let [end, endInput, setEnd] = useInput({
    type: "date",
    label: "End Date",
    initialValue: undefined
  })

  let data = useSelector(getWorkData);



  const getCurrentState = (work) => {
    let currentState;
      if(work) {
          if (props.view === 'po') {
              currentState = work.po_approved ? "approved" : "start"
          } else if (props.view === 'invoice') {
              currentState = work.invoice_approved ? "approved" : work.ready_to_approve ? "ready_to_approve" : "start"
          }
      }
      else {
          currentState = "start";
      }
      return currentState
  }

  const onStageChange = e => {
      let currentState = 'start';
      if(selectedWork) {
        currentState = getCurrentState(selectedWork);
      }
      else if(selected && Object.entries(selected).length > 0){
        currentState = getCurrentState(Object.entries(selected)[0][1]);
      }
      let url;
      let validationResult = filteredWork.results.reduce((newVal, current)=> {
        let valid = validateBeforeApproval(current, props.view);
        return valid && newVal
      }, true);

      if(validationResult || (currentState === "start" && props.view === "invoice")) {
        if(props.view === 'po'){
            url = 'api/work/approve';
            filteredWork.results.forEach((work) => work.po_approved = true);
        }
        else if(props.view === 'invoice'){
            if(currentState !== 'ready_to_approve'){
                url = 'api/work/readytoapprove'
                filteredWork.results.forEach((work) => work.ready_to_approve = true);
            }
            else if(currentState === 'ready_to_approve'){
                url = 'api/work/approve'
                filteredWork.results.forEach((work) => work.invoice_approved = true);
            }
        }
        let conf = {
          data: filteredWork.results,
          method: "put",
          url: url
        }
        axios(conf).then((res) => {
          dispatch(createSetDataFreshAction(dataFresh + 1));
          setStateModalOpen(false);
          setSelected({});
          setMultiStateChange(false);
        }).catch(err => {
          NotificationManager.error("There was an error aaaaaa");
        })
      }
      else{
        NotificationManager.warning("One or more work items are not valid for approval");
      }
  }
  const dataFresh = useSelector(getDataFresh);
  let workTypesLoaded = useWorkTypeData({}, dataFresh);
  let workTypes = useSelector(getWorkTypeData);

  const workTypeObjects = workTypes ? workTypes.results.reduce((obj, item) => {
    return {
      ...obj,
      [item["id"]]: item
    };
  }, {}) : [];

  const onWorkTypeChange = (e, index) => {
    e.stopPropagation();
    let currentData = {...data};
    if(e.target.value !== 'BLANK') {
      currentData.results[index].work_type = workTypeObjects[e.target.value];
    }
    else {
      currentData.results[index].work_type= null;
    }
    dispatch(createSetWorkDataAction(currentData));
  }

  const saveMultiple = (invoiceNumber, paymentDate) => {
    const toSave = Object.values(selected).filter((item, pos, arr) => {
      return (
        arr
          .map(mapObj => mapObj.job.toString() + mapObj.chargeable.toString())
          .indexOf(item.job.toString() + item.chargeable.toString()) === pos
      );
    });

    let promises = [];

    let chargeableAndNonChargeable = [];

    toSave.forEach(work => {
      if (props.view === "po") {
        promises.push(
          updateWorkshopInvoice(
            props.endpoint,
            work.job,
            invoiceNumber,
            paymentDate
          )
        );
      } else {
        // If we are only updating one or the other
        if (toSave.filter(item => item.job === work.job).length === 1) {
          if (work.chargeable) {
            promises.push(
              updateHireCustomerInvoice(props.endpoint, work.job, invoiceNumber)
            );
          } else {
            promises.push(
              updateCustomerInvoice(props.endpoint, work.job, invoiceNumber)
            );
          }
        } else {
          // If we need to update both we don't want to overwrite one, so store them
          chargeableAndNonChargeable.push(work);
        }
      }
    });

    // Condense the ones where we update both
    const toSaveBothValues = chargeableAndNonChargeable.filter(
      (item, pos, arr) => {
        return arr.map(mapObj => mapObj.job).indexOf(item.job) === pos;
      }
    );

    toSaveBothValues.forEach(item => {
      promises.push(
        updateBothInvoices(props.endpoint, item.job, invoiceNumber)
      );
    });

    return Promise.all(promises).then(() => {
      dispatch(createSetDataFreshAction(dataFresh + 1));
      setSelected({});
    });
  };

  const onWorkSave = (work, setEditable, view, index) => {
    if(view === "po") {
      updateWorkshopInvoice(props.endpoint, work.job, work.job_workshop_invoice_number, (work.job_workshop_payment_date || null));
    }
    else if(view === "invoice"){
      if(work.chargeable){
        updateHireCustomerInvoice(props.endpoint, work.job, work.job_customer2_invoice_number);
      }
      else {
        updateCustomerInvoice(props.endpoint, work.job, work.job_customer_invoice_number);
      }
    }
    let dataToSave = work;
    const conf = {
        data: dataToSave,
        method: "put",
        url: "api/work/update/"+ work.id
    }
    return axios(conf).then(res => {
        setEditable(false);
        dispatch(createSetDataFreshAction(dataFresh + 1));
    }).catch(err => {
        NotificationManager.error("There was an error")
    })
  }

  let mapping = {
    po: {
      title: "Workshop Accounting",
      contact: "supplier",
      contactFinder: "SP",
      figures: [
        {name: "Labour Total", prop: "total_labour"},
        {
          name: "Workshop Supplied Parts Total",
          prop: "total_workshop_supplied_part_cost"
        }
      ],
      headings: [
        {
          label: "Job No."
        },
        {
          label: "Registration",
          field: "job__vehicle",
          labelField: "job__vehicle__registration"
        },
        {
          label: "Workshop",
          field: "job__workshop",
          labelField: "job__workshop__display_name"
        },
        {
          label: "Customer",
          field: "customer",
          labelField: "customer_display_name"
        },
        {
          label: "Date",
          field: "job__finish_date",
          labelField: "job__finish_date",
          date: true
        },
        {label: "Description"},
        {label: "Comments"},
        {label: "Labour Cost"},
        {
          label: "Work Type",
          field: "work_type",
          labelField: "work_type__name"
        },
        {
          label: "Approval Received",
          field: "approval_received",
          labelField: "approval_received"
        },
        {
          label: "Workshop Invoice",
          plusBlanks: true,
          field: "job__workshop_invoice_number",
          labelField: "job__workshop_invoice_number",
        },
        {
          label: "Payment Date",
          field: "job__workshop_invoice_payment_date",
          labelField: "job__workshop_invoice_payment_date",
          date: true
        },
        {label: "Part Name" /*field: "parts__name"*/},
        {label: "Part Number" /*, field: "parts__number"*/},
        {label: "Supplier"},
        {label: "Part Cost"}
      ],
      approvalField: "po_approved",
      invoiceField: "job_workshop_invoice_number",
      rows: (value, index) => (
        <PORows
          workTypes={workTypes}
          onWorkTypeChange={e => onWorkTypeChange(e, index)}
          key={value.id}
          value={value}
          onApprove={openStateChangeModal}
          printCheck={printCheck}
          selected={Object.keys(selected).indexOf(value.id.toString()) !== -1}
          endpoint={props.endpoint}
          refresh={refreshData}
          setInvoiceNo={invoice_value =>
            updateWorkshopInvoiceValue(index, invoice_value)
          }
          updateValue={newWork => updateValue(index, newWork)}
          approvedView={approved === 'approved'}
        />
      ),
      print: (
        <PrintPO paymentDate={printDate} data={selected} ref={componentRef}/>
      ),
      multipleEditModal: (
        <MultipleEditModalWorkshop
          open={editModalOpen}
          setOpen={setEditModalOpen}
          selected={selected}
          saveSelected={saveMultiple}
        />
      ),
      stageChangeModal: (
          <StateChangeModal
            view={"po"}
            open={stateModalOpen}
            setOpen={setStateModalOpen}
            selectedWork={selectedWork}
            filteredWork={filteredWork}
            setFilteredWork={setFilteredWork}
            getCurrentState={getCurrentState}
            multi={multiStateChange}
            setMulti={setMultiStateChange}
            setSelected={setSelected}
            onStageChange={onStageChange}
            workTypeObjects={workTypeObjects}
            workTypes={workTypes}
            onWorkSave={onWorkSave}
          />
      )
    },
    invoice: {
      title: "Customer Invoicing",
      contact: "customer",
      contactFinder: "CC",
      headings: [
        {
          label: "Job #"
        },
        {
          label: "Registration",
          field: "job__vehicle",
          labelField: "job__vehicle__registration"
        },
        {
          label: "Workshop",
          field: "job__workshop",
          labelField: "job__workshop__display_name"
        },
        {
          label: "Customer",
          field: "customer",
          labelField: "customer_display_name"
        },
        {
          label: "Date",
          field: "job__finish_date",
          labelField: "job__finish_date",
          date: true
        },
        {label: "Description"},
        {label: "Customer Labour Charge"},
        {label: "Workshop Invoice"},
        {
          label: "Invoice Number",
          plusBlanks: true,
          field: "job__invoice_number",
          labelField: "job__invoice_number"
        },
        {label: "Part Name"},
        {label: "Part Number"},
        {label: "Part Invoice No"},
        {label: "Supplier"},
        {label: "Customer Charge"},
        {label: "Comments"}
      ],
      figures: [
        {name: "Labour Total", prop: "total_customer_labour"},
        {name: "Parts Total", prop: "total_customer_part_cost"}
      ],
      approvalField: "invoice_approved",
      invoiceField: "job_customer_invoice_number",
      rows: (value, index) => {
        return (
          <InvoiceRows
            key={value.id}
            value={value}
            onStateChange={openStateChangeModal}
            printCheck={printCheck}
            selected={Object.keys(selected).indexOf(value.id.toString()) !== -1}
            endpoint={props.endpoint}
            refresh={refreshData}
            updateValue={newWork => updateValue(index, newWork)}
            setInvoiceNo={
              value.chargeable
                ? invoice_value =>
                  updateHireCustomerInvoiceValue(index, invoice_value)
                : invoice_value =>
                  updateCustomerInvoiceValue(index, invoice_value)
            }
            approvedView={approved === 'approved'}
          />
        );
      },
      print: (
        <PrintInvoice
          paymentDate={printDate}
          data={selected}
          ref={componentRef}
        />
      ),
      multipleEditModal: (
        <MultipleEditModalCustomer
          open={editModalOpen}
          setOpen={setEditModalOpen}
          selected={selected}
          saveSelected={saveMultiple}
        />
      ),
      stageChangeModal: (
          <StateChangeModal
            view={"invoice"}
            open={stateModalOpen}
            setOpen={setStateModalOpen}
            selectedWork={selectedWork}
            filteredWork={filteredWork}
            setFilteredWork={setFilteredWork}
            getCurrentState={getCurrentState}
            multi={multiStateChange}
            setMulti={setMultiStateChange}
            setSelected={setSelected}
            onStageChange={onStageChange}
            workTypeObjects={workTypeObjects}
            workTypes={workTypes}
            onWorkSave={onWorkSave}
          />
      )
    }
  };

  const [page, setPage] = useState(1);
  let view_options = [
      {name: "Current", value: "all"},
      {name: "Ready To Invoice", value: "ready"},
      {name:"Warranty Approval Needed", value:"warranty"},
      {name: "Approved", value: "approved"}
  ]
  if(props.view === 'po'){
    view_options.splice(1, 1);
  }
  if(props.view === 'invoice'){
    view_options.splice(2,1);
  }
  const [approved, approvedInput] = useSelect({
    label: "View",
    options: view_options,
    initialValue: "all",
    testId: "invoice-view",
    callback: () => {
      setPage(1);
    }
  });
  let [selectedDepartment, setSelectedDepartment] = useState();

  let params = {
    page,
    view: props.view
  };
  if (start) {
    params.start = start;
  }
  if (end) {
    params.end = end;
  }
  if (approved === "approved") {
    params.approved = true;
  }
  if (approved === "ready"){
    params.ready = true;
  }
  if (approved === "warranty"){
    params.warranty = true;
  }
  if (selectedDepartment) {
    params.department = selectedDepartment;
  }

  if (currentSearch) {
    params.search = currentSearch;
  }
  if (searchFresh){
    params.searchFresh = searchFresh;
  }
  for (let filter in filters) {
    if (filters[filter][0] && filters[filter][0][filter]) {
      params[filter] = filters[filter][0][filter];
    }
  }


  const complete = useWorkData(params, dataFresh)
  let departmentsLoaded = useDepartmentsData({}, dataFresh);
  let departments = useSelector(getDepartmentsData);


  const onDepartmentSelection = (e) => {
    setSelectedDepartment(e.target.value);
  }


  const updateHireCustomerInvoiceValue = (index, invoiceNumber) => {
    let currentData = {...data};
    currentData.results[index].job_customer2_invoice_number = invoiceNumber;
    dispatch(createSetWorkDataAction(currentData));
  };

  const updateCustomerInvoiceValue = (index, invoiceNumber) => {
    let currentData = {...data};
    currentData.results[index].job_customer_invoice_number = invoiceNumber;
    dispatch(createSetWorkDataAction(currentData));
  };

  const updateWorkshopInvoiceValue = (index, invoiceNumber) => {
    let currentData = {...data};
    currentData.results[index].job_workshop_invoice_number = invoiceNumber;
    dispatch(createSetWorkDataAction(currentData));
  };

  const updateValue = (index, newWork) => {
    let currentData = {...data};
    currentData.results[index] = newWork;
    dispatch(createSetWorkDataAction(currentData));
  };


  const handleReadyToApprove = (work, multi) => {
      const endpoint = props.endpoint + "work/readytoapprove";
      let data = multi ? work : [work]; // Causes array of arrays
      const conf = {
        method: "put",
        data: data,
        url: endpoint
      };

      axios(conf).then(() => {
        refreshData();
      }).catch(err => {
        NotificationManager.error("There was an error");
      })
  }

  const getMultiFilteredWork = (view) => {
    if(selected && Object.entries(selected).length > 0){
      let currentState = getCurrentState(Object.entries(selected)[0][1]); //All selected items should share a state
      let additionalFilter;
      let jobs = [];
      let promise = [];
      if(currentState === "ready_to_approve"){
        additionalFilter= "ready=true"
      }

      Object.entries(selected).forEach(work => {
        if(!jobs.includes(work[1].job)){
          jobs.push(work[1].job)
        }
      });
      jobs.forEach(job => {
        let conf = {
          url: props.endpoint + `work?view=${view}&job=${job}&${additionalFilter}`,
          method: 'get',
          cache: false
        }
        setLoadingModal(true);

        promise.push(axios(conf));

      });
      Promise.all(promise).then(res => {
        setFilteredWork({results: res.reduce((newVal, current) => {
            return newVal.concat(current.data.results);
          }, [])
        });
        setLoadingModal(false);
        })
    }
  }

  const onSelectStateChange = e => {
    setMultiStateChange(true);
    setStateModalOpen(true);
    getMultiFilteredWork(props.view);
  };


  const getFilteredWork = (view) => {
    if(selectedWork) {
      let currentState = getCurrentState(selectedWork);
      let additionalFilter;
      if(currentState === "ready_to_approve"){
        additionalFilter= "ready=true"
      }

      let conf = {
        url: props.endpoint + `work?view=${view}&job=${selectedWork.job}&${additionalFilter}`,
        method: 'get'
      }
      return axios(conf).then(res => {
        setFilteredWork(res.data);
      }).catch(err => {
        NotificationManager.error("There was an error")
      })
    }
  }

  const openStateChangeModal = (work) => {
    setSelectedWork(work);
    setStateModalOpen(true);
  }



  const toggleSelectVisible = () => {
    let newSelected = {...selected};
    let selectedKeys = Object.keys(newSelected);
    const notPresent = data.results.find(
      workItem => selectedKeys.indexOf(workItem.id.toString()) === -1
    );

    if (notPresent) {
      data.results.forEach(item => {
        newSelected[item.id.toString()] = item;
      });
    } else {
      data.results.forEach(item => {
        delete newSelected[item.id.toString()];
      });
    }
    setSelected(newSelected);
  };

  const refreshData = () => {
    dispatch(createSetDataFreshAction(dataFresh + 1));
    setSelected({});
  };

  const handleSearch = () => {
    setPage(1);
    setCurrentSearch(searchText);
    setSearchFresh(searchFresh+1);
  };

  const clear = () => {
    setSearchText("");
    setCurrentSearch("");
    setPage(1);
  };

  const printCheck = item => {
    let newPrintSelection = {...selected};
    if (Object.keys(selected).indexOf(item.id.toString()) === -1) {
      newPrintSelection[item.id.toString()] = item;
    } else {
      delete newPrintSelection[item.id.toString()];
    }
    setSelected(newPrintSelection);
  };

  if (!complete || !data || !departmentsLoaded || !workTypesLoaded) {
    return (
      <div className="spinner-centre">
        <Spinner
          className="spinner-centre"
          radius={120}
          color={"#3273dc"}
          stroke={5}
        />
      </div>
    );
  }

  document.title = mapping[props.view].title;

  return (
    <div>
      <Box>
        <h1 className="title">{mapping[props.view].title}</h1>
        <Columns>
          {approvedInput}
          <Columns.Column>
            <DepartmentPicker
              departments={departments}
              onDepartmentChange={onDepartmentSelection}
              selectedDepartment={selectedDepartment}
            />
          </Columns.Column>
          {startInput}
          {endInput}
          <Columns.Column size={8}>
            <Form.Label>Search</Form.Label>
            <SearchBar
              handleSearch={handleSearch}
              clear={clear}
              setSearchText={setSearchText}
              searchText={searchText}
            />
          </Columns.Column>
        </Columns>
      </Box>
      <div style={{display: "none"}}>{mapping[props.view].print}</div>
      <FiguresSection data={data} figures={mapping[props.view].figures}/>
      <Box>
        <Table striped={false} className="is-hoverable">
          <thead>
          <tr className="small-row-black">
            {mapping[props.view].headings.map(heading => {
              if (!heading.field) {
                return <th key={heading.label}>{heading.label}</th>;
              }
              if (heading.blank) {
                return (
                  <FilterBlankHeading
                    key={heading.label}
                    heading={heading}
                    setFilters={f => {
                      setPage(1);
                      setFilters(f);
                    }}
                    filters={filters}
                  />
                );
              }
              return (
                <FilterHeading
                  key={heading.label}
                  heading={heading}
                  setFilters={f => {
                    setPage(1);
                    setFilters(f);
                  }}
                  filters={filters}
                  options={data.filter_list[heading.field]}
                />
              );
            })}
            {approved === 'approved' && <th>Approver</th>}
            <th>
              <Button
                onClick={toggleSelectVisible}
                color={
                  data.results.find(
                    workItem =>
                      Object.keys(selected).indexOf(
                        workItem.id.toString()
                      ) === -1
                  )
                    ? ""
                    : "danger"
                }
              >
                {data.results.find(
                  workItem =>
                    Object.keys(selected).indexOf(workItem.id.toString()) ===
                    -1
                ) ? (
                  <div>
                    <FAIcon size="small" icon={["fas", "check"]}/>
                    <span>Visible</span>
                  </div>
                ) : (
                  <div>
                    <FAIcon size="small" icon={["fas", "times"]}/>
                    <span>Visible</span>
                  </div>
                )}
              </Button>
            </th>
            {!!Object.entries(selected).length && (
              <th>
                <Button onClick={() => setSelected({})} color="danger">
                  <FAIcon size="small" icon={["fas", "times"]}/>
                </Button>
              </th>
            )}
          </tr>
          </thead>
          <tbody>{data.results.map(mapping[props.view].rows)}</tbody>
        </Table>
        <div className={"second-delete"}>
          <Button
            onClick={toggleSelectVisible}
            color={
              data.results.find(
                workItem =>
                  Object.keys(selected).indexOf(
                    workItem.id.toString()
                  ) === -1
              ) ? "" : "danger"
            }
            className={"second-delete-button"}
          >
            {data.results.find(
              workItem =>
                Object.keys(selected).indexOf(workItem.id.toString()) ===
                -1
            ) ? (
              <div>
                <FAIcon size="small" icon={["fas", "check"]}/>
                <span>Visible</span>
              </div>
            ) : (
              <div>
                <FAIcon size="small" icon={["fas", "times"]}/>
                <span>Visible</span>
              </div>
            )
            }
          </Button>
        </div>
        <Pagination
          showFirstLast={true}
          onChange={page => setPage(page)}
          current={page}
          total={Math.ceil(data.count / 25)}
        />
      </Box>
      <Box>
        <Columns>
          <Columns.Column>
            <Button
              fullwidth
              onClick={() => setEditModalOpen(true)}
              disabled={!Object.entries(selected).length}
              color={Object.entries(selected).length ? "primary" : ""}
            >
              Edit Selected
            </Button>
          </Columns.Column>
          <Columns.Column>
            <Button
              fullwidth
              onClick={() => setModalOpen(true)}
              color={Object.entries(selected).length ? "warning" : ""}
              disabled={!Object.entries(selected).length}
            >
              Print Selected
            </Button>
          </Columns.Column>
          {(approved === "all" && props.view === "invoice") && (
            <Columns.Column>
              <Button
                fullwidth
                onClick={() => {
                  onSelectStateChange();
                }}
                color={Object.entries(selected).length ? "success" : ""}
                disabled={!Object.entries(selected).length}
              >
                Ready To Invoice Selected
              </Button>
            </Columns.Column>
          )}
          {(approved === "ready" || props.view === 'po') && (
            <Columns.Column>
              <Button
                fullwidth
                onClick={() => {
                  onSelectStateChange()
                }}
                color={Object.entries(selected).length ? "success" : ""}
                disabled={!Object.entries(selected).length}
              >
                Approve Selected
              </Button>
            </Columns.Column>
          )}
        </Columns>
      </Box>
      <Modal closeOnBlur show={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Content>
          <Section style={{backgroundColor: "white"}}>
            {props.view === "invoice" && (
              <Form.Field>
                <Form.Control>
                  <Form.Label>Select an Invoice Date</Form.Label>
                  <Form.Input
                    type="date"
                    value={printDate || ""}
                    onChange={e => setPrintDate(e.target.value)}
                  />
                </Form.Control>
              </Form.Field>
            )}
            <Form.Field>
              <p>
                You have selected {Object.keys(selected).length} out of total of{" "}
                {data.count} items for printing
              </p>
            </Form.Field>
            <ReactToPrint
              trigger={() => (
                <Button
                  color={Object.entries(selected).length ? "success" : ""}
                >
                  Print Selected
                </Button>
              )}
              content={() => componentRef.current}
            />
          </Section>
        </Modal.Content>
      </Modal>
      {mapping[props.view].multipleEditModal}
      {mapping[props.view].stageChangeModal}
      <LoadingModal
        open={loadingModal}
        setOpen={setLoadingModal}
      />
    </div>
  );
}

export default InvoicePO;
