import React, {useState} from "react";
import {useVehiclesData} from "../../../hooks/useVehiclesData";
import {getDataFresh, getVehiclesData} from "../../../state/selectors";
import LoadingSpinner from "../../Utils/Spinner";
import {Box, Columns, Heading, Table} from "react-bulma-components";
import SearchBar from "../../Utils/SearchBar";
import {useSelector} from "react-redux";
import KeyFinderRow from "./KeyFinderRow";

export default function KeyFinder(props){
    const [searchText, setSearchText] = useState("");
    const [currentSearch, setCurrentSearch] = useState("");
    let params = {keys: true, page_size:500};

    if (currentSearch) {
        params.search = currentSearch;
    }

    const handleSearch = () => {
        setCurrentSearch(searchText);
    };

    const clear = () => {
        setCurrentSearch("");
        setSearchText("");
    };

    let dataFresh = useSelector(getDataFresh);
    let complete = useVehiclesData(params, dataFresh);
    let data = useSelector(getVehiclesData);



    if(!complete){
        return (
            <LoadingSpinner/>
        )
    }

    return (
        <div>
            <Box>
               <Heading>Key Finder</Heading>
               <Columns>
                   <Columns.Column>
                       <SearchBar
                          handleSearch={handleSearch}
                          setSearchText={setSearchText}
                          searchText={searchText}
                          clear={clear}
                       />
                   </Columns.Column>
               </Columns>
            </Box>
            <Box>
                <Columns>
                    <Columns.Column>
                        <Table size={"fullwidth"}>
                            <thead>
                                <th>Registration</th>
                                <th>Key Number</th>
                            </thead>
                            <tbody>
                            {data.results.map(vehicle => (
                                <KeyFinderRow
                                    data={vehicle}
                                />
                            ))}
                            </tbody>
                        </Table>
                    </Columns.Column>
                </Columns>
            </Box>
        </div>
    )


}