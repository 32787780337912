import React from "react";
import {Box, Button, Heading, Modal, Section} from "react-bulma-components";
import KeyPicker from "../Vehicle/KeyPicker";
import {useKeysData} from "../../hooks/useKeysData";
import {useSelector} from "react-redux";
import {getKeysData} from "../../state/selectors";
import axios from "axios";



function KeyChangeModal({keys,open, setOpen, triggerChange, vehicle, save, selectedKey, filterField, onClear}){

    const keyObjects = keys.results.reduce((obj, item) => {
      return {
        ...obj,
        [item["id"]]: item
      };
    }, {});



    const onChange = e => {
        vehicle.key = keyObjects[e.target.value];
        triggerChange(vehicle);
    }

    const onButtonClick = (e) => {
        save();
        setOpen(false);
    }

    return (
        <Modal
            closeOnBlur
            show={open}
            closeOnEsc={true}
            onClose={() => {
                setOpen(false);
            }}
        >
            <Modal.Content>
                <Section style={{ backgroundColor: "white" }}>
                    <Heading className={"is-centered"}>Change key</Heading>
                    <KeyPicker
                        disabled={false}
                        keys={keys}
                        clearButton={true}
                        onKeyChange={onChange}
                        selectedKey={selectedKey}
                        filterField={filterField}
                        onClear={onClear}
                        spare={false}
                        filter={false}
                    />
                    <Button
                        onClick={onButtonClick}
                        color={"success"}
                        fullwidth={true}
                    >
                        Save
                    </Button>
                </Section>
            </Modal.Content>
        </Modal>
    )






}

export default KeyChangeModal;