import React from "react";
import {Heading, Table} from "react-bulma-components";
import {readableDate} from "../../utils";
import logo from "../Nav/img/Logo.jpg";
import {Columns} from "react-bulma-components";
import PrintPickList from "./PrintPickList";

class PrintJob extends React.Component {
  PartRow(part) {
    return (
      <tr key={`p${part}`}>
        <td>{part}</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
  }

  WorkRow(work, partName) {
    let rows = [];
    rows.push(
      <tr key={work.id} className="table-print-highlight">
        {/*<td>{partName}</td>*/}
        <td>{work.description}</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
    return rows;
  }

  render() {
    const fixHistory = (history) => {
      return history.replace("\n", "<br/>")
    };

    const parts = [];
    const totals = this.props.data.work_items.reduce(
      (sum, curr) => {
        return {
          labour_cost: 1 * sum.labour_cost + 1 * curr.labour_cost,
          cost:
            1 * sum.cost +
            curr.parts.reduce((partsSum, currentPart) => {
              parts.push(currentPart.name);
              return 1 * partsSum + 1 * currentPart.cost;
            }, 0)
        };
      },
      {labour_cost: 0, cost: 0}
    );

    parts.sort().reverse();

    let rows = Object.entries(this.props.data.work_items)
      .filter((work) => !work[1].complete)
      .sort((a, b) => a[0].description < b[0].description)
      .map(entry => this.WorkRow(entry[1], parts.pop()));

    // if (parts.length) {
    //   parts.forEach(part => {
    //     rows.push(this.PartRow(part));
    //   });
    // }

    let job = this.props.data;
    const {vehicle} = job;
    const partsList = job.work_items.reduce((allParts, workItem) => {
      const parts = workItem.parts.map((part) => {
        return {
          ...part,
          workDescription: workItem.description
        }
      });
      return [...allParts, ...parts];
    }, []);
    const totalPartRows = 15 - partsList.length;
    const totalRows =
      this.props.data.work_items.length;
    // this.props.data.work_items.reduce((total, next) => {
    //   return total + next.parts.length;
    // }, 0);
    let blankRows = [];
    for (let i = 0; i < totalPartRows; i++) {
      blankRows.push(
          <tr>
            <td>&nbsp;</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
      );
    }
    const noAdditionalRows = 10 - totalRows;

    const additionalRows = [];

    for (let i = 0; i < noAdditionalRows; i++) {
      additionalRows.push(
        <tr key={`a${i}`}>
          <td>&nbsp;</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      );
    }

    let heading = `${job.workshop ? job.workshop.display_name : ""} - ${job.vehicle ? job.vehicle.registration : ""}${job.vehicle ? job.vehicle.vin_number ? '/' + job.vehicle.vin_number : "" : ""} - Workshop Date: ${readableDate(job.workshop_date)}`

    return (
      <div>
        <Columns>
          <img src={logo} style={{width: '110px', height: '35px'}} alt="Rentals Direct Ltd"/>
          <h2 className="job-card-print-title" data-testid={"title-1"}>
            {heading}
          </h2>
        </Columns>

        <Columns>
          <Columns.Column className="is-one-quarter">
            <Table bordered size={"fullwidth"}>
              <tbody>
              <tr>
                <td className="header-row">Booked In Date</td>
                <td data-testid={"job-book-in"}>{readableDate(job.book_in_date)}</td>
              </tr>
              <tr>
                <td className="header-row">Make</td>
                <td data-testid={"vehicle-make"}>{job.vehicle ? job.vehicle.make ? job.vehicle.make.name : "" : ""}</td>
              </tr>
              <tr>
                <td className="header-row">Model</td>
                <td data-testid={"vehicle-model"}>{job.vehicle ? job.vehicle.model ? job.vehicle.model.name : "" : ""}</td>
              </tr>
              <tr>
                <td className={"header-row"}>Variant</td>
                <td data-testid={"vehicle-variant"}>{job.vehicle ? job.vehicle.variant ?? "" : ""}</td>
              </tr>
              <tr>
                <td className="header-row">Customer</td>
                <td data-testid={"customer1-1"}>{job.customer ? job.customer.display_name : ""}</td>
              </tr>
              <tr>
                <td className="header-row">Customer 2</td>
                <td data-testid={"customer2-1"}>
                  {job.customer2 ? job.customer2.display_name : ""}
                </td>
              </tr>
              <tr>
                <td className="header-row">Labour Cost</td>
                <td data-testid={"labour-cost"}>
                  £
                  {totals.labour_cost.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
              </tr>
              <tr>
                <td className="header-row">Part Cost</td>
                <td data-testid={"part-cost"}>
                  £
                  {totals.cost.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
              </tr>
              <tr>
                <td className="header-row">Total Cost</td>
                <td data-testid={"total-cost"}>
                  £
                  {(totals.labour_cost + totals.cost).toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }
                  )}
                </td>
              </tr>
              <tr>
                <td className="header-row">Workshop Charge</td>
                <td></td>
              </tr>
              <tr>
                <td className="header-row">Workshop Material Charge</td>
                <td></td>
              </tr>
              <tr>
                <td className="header-row">Workshop Sundries</td>
                <td></td>
              </tr>
              <tr>
                <td className="header-row">Job No.</td>
                <td data-testid={"job-id-1"}>{job.id}</td>
              </tr>
              <tr>
                <td className={"header-row"}>Date Of First Registration</td>
                <td data-testid={"vehicle-dor"}>{readableDate(job.vehicle ? job.vehicle.first_registration_date : "")}</td>
              </tr>
              <tr>
                <td className={"header-row"}>Key</td>
                <td data-testid={"vehicle-key"}>{job.vehicle ? job.vehicle.key ? job.vehicle.key.key_number : "" : ""}</td>
              </tr>
              </tbody>
            </Table>
          </Columns.Column>
          <Columns.Column>
            <div>
              <Table bordered size={"fullwidth"}>
                <thead>
                <tr className="header-row">
                  <td>Job Description</td>
                  <td>Comments</td>
                  <td style={{fontSize: "small"}}>Work Supervisor<br/>Signature</td>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{job.job_description}</td>
                  <td
                    colSpan={2}>{job.comments_list.length > 0 ? job.comments_list[job.comments_list.length - 1].text : ""}</td>
                </tr>
                </tbody>
              </Table>
              <Table bordered size={"fullwidth"}>
                <tbody>
                <tr>
                  <th className="narrow-column">Fitter Name</th>
                  <td></td>
                  <th className="narrow-column">Mileage</th>
                  <td></td>
                </tr>
                </tbody>
              </Table>
              {job.history &&
                  <Table bordered size={"fullwidth"}>
                    <thead>
                      <tr>
                        <td className="header-row">History</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {(job.history)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
              }
              <div className={"page-break"}>
                <Table bordered size={"fullwidth"}>
                  <thead>
                  <tr className="header-row">
                    <td className="double-wide-column">Items</td>
                    <td className="narrow-column">Item Done</td>
                    <td className="narrow-column">Item Not Done</td>
                    <td className="narrow-column">QC</td>
                  </tr>
                  </thead>
                  <tbody>
                  {Object.entries(this.props.data.work_items).length > 0 &&
                    rows}
                  {additionalRows.map(row => row)}
                  </tbody>
                </Table>
              </div>
            </div>
          </Columns.Column>
        </Columns>
        <Columns>
          <PrintPickList data={job}/>
        </Columns>
      </div>
    );
  }
}

export default PrintJob;
