import React from "react"
import {Columns, Table} from "react-bulma-components";
import {readableDate} from "../../utils";
import PrintCustomerCollectionSheet from "./PrintCustomerCollectionSheet";


class PrintSalesInvoice extends React.Component{

    render() {
        let vehicle = this.props.vehicle;
        let financeAgreement = this.props.finance;
        let totalPrice = parseFloat(financeAgreement.sold_price) + parseFloat(financeAgreement.sale_warranty_cost) + (financeAgreement.sold_price * 0.2)
        let service = this.props.serviceData;
        return (
            <div className={"print-background"}>
                <Columns>
                    <Columns.Column>
                        <Table bordered size={"fullwidth"}>
                            <tbody>
                                <tr>
                                    <th className={"table-header-bold out-border-top-black out-border-middle-black"}>RENTALS DIRECT LTD</th>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold out-border-middle-black out-border-bottom-black"}>Tibenham, Norfolk NR16 1PH</th>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold border-black small-text"}>Tel: 01953 665421  Email: sales@rentalsdirect4u.co.uk</th>
                                </tr>
                            </tbody>
                        </Table>
                        <Table bordered size={"fullwidth"}>
                            <tbody>
                                <tr>
                                    <th colSpan={4} className={"table-header-bold fill-grey border-black"}>PRO FORMA</th>
                                </tr>
                                <tr>
                                    <th colSpan={2} className={"table-header-bold fill-grey border-black small-text"}>INVOICE ADDRESS :</th>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>PRO FORMA NUMBER :</th>
                                    <td className={"wide-column border-black"} data-testId={"pro-forma-number"}>{vehicle.fleet_number}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} className={"wide-column out-border-top-black out-border-middle-black"} data-testId={"sale-invoice-address"}>{financeAgreement.sale_invoice_address}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>DATE :</th>
                                    <td className={"wide-column border-black"} data-testId={"sale-invoice-date"}>{readableDate(financeAgreement.internal_sale_date)}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} className={"wide-column out-border-middle-black"}></td>
                                    <th colSpan={2} className={"table-header-bold fill-grey border-black small-text"}>NAME</th>
                                </tr>
                                <tr>
                                    <td colSpan={2} className={"wide-column out-border-middle-black out-border-bottom-black"}></td>
                                    <td colSpan={2} className={"wide-column out-border-top-black out-border-middle-black"} data-testId={"sale-customer"}>{financeAgreement.sale_customer ? financeAgreement.sale_customer.display_name : ""}</td>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>POST CODE :</th>
                                    <td className={"wide-column border-black"} data-testId={"postcode"}>{financeAgreement.sale_customer_postcode}</td>
                                    <td colSpan={2} className={"wide-column out-border-middle-black out-border-bottom-black"}></td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table bordered size={"fullwidth"}>
                            <tbody>
                                <tr>
                                    <th colSpan={4} className={"table-header-bold fill-grey border-black small-text"}>DESCRIPTION</th>
                                </tr>
                                <tr>
                                    <td colSpan={4} className={"wide-column border-black"}>
                                        USED VEHICLE - SOLD AS SEEN - {vehicle.make ? vehicle.make.name : ""} - {vehicle.model ? vehicle.model.name : ""} - {vehicle.variant}
                                    </td>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>REGISTRATION :</th>
                                    <td className={"wide-column border-black"} data-testId={"vehicle-reg"}>{vehicle.registration}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>DATE OF FIRST REG. :</th>
                                    <td className={"wide-column border-black"} data-testId={"vehicle-dofr"}>{readableDate(vehicle.first_registration_date)}</td>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>CHASSIS NUMBER :</th>
                                    <td className={"wide-column border-black"} data-testId={"vehicle-vin"}>{vehicle.vin_number}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>MILEAGE :</th>
                                    <td className={"wide-column border-black"} data-testId={"vehicle-mileage"}>{vehicle.mileage}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table bordered size={"fullwidth"}>
                            <tbody>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>SALES PRICE :</th>
                                    <td className={"wide-column border-black"} data-testId={"sold-price"}>£{financeAgreement.sold_price}</td>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>EXTRA WARRANTY COST :</th>
                                    <td className={"wide-column border-black"} data-testId={"warranty-cost"}>£{financeAgreement.sale_warranty_cost}</td>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>SUB TOTAL :</th>
                                    <td className={"wide-column border-black"} data-testId={"sub-total"}>£{parseFloat(financeAgreement.sold_price) + parseFloat(financeAgreement.sale_warranty_cost)}</td>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>VAT 20% :</th>
                                    <td className={"wide-column border-black"} data-testId={"vat"}>£{financeAgreement.sold_price * 0.2}</td>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>TOTAL :</th>
                                    <td className={"wide-column border-black"} data-testId={"total"}>£{totalPrice}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table bordered size={"fullwidth"}>
                            <tbody>
                                <tr>
                                    <th colSpan={4} className={"table-header-bold fill-grey border-black small-text"}>BALANCE :</th>
                                    <td colSpan={4} className={"wide-column border-black"} data-testId={"balance"}>£{totalPrice}</td>
                                </tr>
                                <tr>
                                    <th colSpan={4} className={"table-header-bold fill-grey border-black small-text"}>LESS DEPOSIT PAID :</th>
                                    <td colSpan={4} className={"wide-column border-black"} data-testId={"deposit"}>£{financeAgreement.sale_deposit}</td>
                                </tr>
                                <tr>
                                    <th colSpan={4} className={"table-header-bold fill-grey border-black small-text"}>OUT STANDING BALANCE :</th>
                                    <td colSpan={4} className={"wide-column border-black"} data-testId={"outstanding-balance"}>£{totalPrice-financeAgreement.sale_deposit}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table bordered size={"fullwidth"}>
                            <tbody>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>VEHICLE TO BE
                                        DELIVERED TO :
                                    </th>
                                </tr>
                                <tr>
                                    <td className={"wide-column border-black"} data-testId={"delivery-address"}>{financeAgreement.sale_delivery_address}</td>
                                </tr>
                                <tr>
                                    <td className={"wide-column border-black"}></td>
                                </tr>
                                <tr>
                                    <td className={"wide-column border-black"}></td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table bordered size={"fullwidth"}>
                            <tbody>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>PAYMENT DETAILS :</th>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold border-black small-text"}>LLOYDS BANK PLC</th>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold border-black small-text"}>RENTALS DIRECT LTD</th>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold border-black small-text"}>SORT CODE : 30 64 22</th>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold border-black small-text"}>ACCOUNT NUMBER : 32521360</th>
                                </tr>
                            </tbody>
                        </Table>
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Columns.Column>
                        <p className={"table-header-bold small-text"}>Vehicle Sales/Daily & Long Term Van Hire Available</p>
                        <p className={"table-header-bold small-text"}>Rentals Direct Ltd. Registered Address : Unit 3, Cherry Tree Road, Tibenham, Norwich, Norfolk NR16 1PH</p>
                        <p className={"table-header-bold small-text"}>Company Registration 06980659 - VAT Registration Number - 981-3902-05</p>
                    </Columns.Column>
                </Columns>
                <PrintCustomerCollectionSheet vehicle={vehicle} finance={financeAgreement} service={service}/>
            </div>
        )
    }

}

export default PrintSalesInvoice