import React, {useState} from "react";
import {Columns, Heading, Modal, Section, Form, Box, Table, Button} from "react-bulma-components";
import axios from "axios";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import {useSelector} from "react-redux";
import {getCostCentresData} from "../../state/selectors";
import Spinner from "react-spinner-material";
import PaymentRow from "../Invoicing/PaymentRow"
import {NotificationManager} from "react-notifications";
import {readableDate} from "../../utils";

export default function PaymentModal({open, setOpen, financeAgreement, endpoint, vehicle}){

    let totalPrice = parseFloat(financeAgreement.sold_price) + parseFloat(financeAgreement.sale_warranty_cost) + (financeAgreement.sold_price * 0.2);
    const costCentres = useSelector(getCostCentresData);

    const costCentreObjects = costCentres.results.reduce((obj, item) => {
        return {
          ...obj,
          [item["id"]]: item
        };
    }, {});


    const [newPayment, setNewPayment] = useState(
        {
            contact: financeAgreement.sale_customer,
            invoice_number: vehicle.fleet_number,
            amount: totalPrice-financeAgreement.sale_deposit,
            payment_type: 'BACS',
            cost_centre: costCentreObjects[4], // Default to sales
            payment_date: financeAgreement.sale_date,
            comments: ""

        }
    );

    const onChange = (e, index) => {
        let payment = {...newPayment, [e.target.name]: e.target.value}
        setNewPayment(payment);
    }

    const onSave = () => {
        let conf = {
            method: "post",
            url: endpoint+"payments",
            data: newPayment
        }
        axios(conf).then((res) => {
            setOpen(false);
        }).catch((err) => {
            NotificationManager.error("There was an error")
        })
    }


    return (
        <Modal
            closeOnBlur
            show={open}
            onClose={() => setOpen(false)}
        >
            <Modal.Content className={"wider-modal"}>
                <Section style={{background: "white"}}>
                    <Columns>
                        <Columns.Column>
                            <Heading>Create Payment</Heading>
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Table>
                            <thead>
                                <th>Customer</th>
                                <th>Invoice Number</th>
                                <th>Amount</th>
                                <th>Payment Method</th>
                                <th>Cost Centre</th>
                                <th>Payment Date</th>
                                <th>Comments</th>
                            </thead>
                            <tbody>
                                <PaymentRow
                                    endpoint={endpoint}
                                    payment={newPayment}
                                    costCentres={costCentres.results}
                                    costCentreObjects={costCentreObjects}
                                    handleChange={onChange}
                                    allowDelete={false}
                                />
                            </tbody>
                        </Table>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <Button
                                color={"danger"}
                                onClick={(e) => {
                                    setOpen(false);
                                }}
                                fullwidth
                            >
                                Cancel
                            </Button>
                        </Columns.Column>
                        <Columns.Column>
                            <Button
                                color={"success"}
                                onClick={onSave}
                                fullwidth
                            >
                                Save
                            </Button>
                        </Columns.Column>
                    </Columns>
                </Section>
            </Modal.Content>
        </Modal>
    )


}