import React from "react";


export default function KeyFinderRow({data}){


    return (
        <tr>
            <td>{data.registration}</td>
            <td>{data.key.key_number}</td>
        </tr>
    )
}