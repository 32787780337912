import React from "react";
import {Button, Columns, Form} from "react-bulma-components";
import {useDispatch} from "react-redux";
import axios from "axios";
import {createSetDataFreshAction} from "../../actions/dataActions";
import NotificationManager from "react-notifications/lib/NotificationManager";


function KeyPicker({keys, selectedKey, onKeyChange, onClear, filterField, filter, disabled, spare=false}) {

  let filteredKeys = keys.results;
  if (filter) {
    filteredKeys = filteredKeys.filter((key) => (key.board === filterField || key.board === "EXTERNAL"))
  }
  filteredKeys = filteredKeys.filter((key) => (key.spare === spare))

  return (
    <div>
      <Columns>
        <Columns.Column>
          <Form.Label>{spare ? 'Spare Key Selector' : 'Key Selector'}</Form.Label>
          <Form.Field className={'has-addons'}>
            <Form.Control>
              <Form.Select
                name={spare ? "spare_key" : "key"}
                value={selectedKey ? selectedKey.id : 0}
                onChange={e => onKeyChange(e)}
                disabled={disabled}
              >
                <option></option>
                {filteredKeys.map(value => (
                  <option key={value.id} value={value.id}>
                    {spare ? value.board + " " + value.key_number : value.board === "EXTERNAL" ? value.board + " " + value.key_number : value.key_number}
                  </option>
                ))}
              </Form.Select>
            </Form.Control>
            <Form.Control>
              <Button
                  className="small-row-white"
                  color="danger"
                  disabled={disabled}
                  onClick={() => {
                    onClear(spare)
                  }}
              >
                  Unassign Key
              </Button>
            </Form.Control>
          </Form.Field>
        </Columns.Column>
      </Columns>


    </div>
  )


}

export default KeyPicker;