import React, {useState} from "react";
import { Button } from "react-bulma-components";
import { Form } from "react-bulma-components";
import {formatDate, readableDate} from "../../utils";



function Comment({
                   comment,
                   index,
                   updateComments,
                   removeComment,
                   disabled
                 }) {

  const handleChange = e => {
    const newComment = getNewComment(e);
    updateComments(newComment, index);
  };

  const getNewComment = e => {
    let newComment = {...comment, [e.target.name]: e.target.value};

    return newComment;
  };

  return (
      <tr>
        <td className={"comments-wrapper"}>
          <Form.Field className="has-addons">
            <Form.Control className="comments-wrapper">
              <Form.Input
                  type="text"
                  value={comment.text}
                  name="text"
                  onChange={handleChange}
                  data-testid="part-name"
                  className={"comments-box"}
                  disabled={disabled}
              />
            </Form.Control>
          </Form.Field>
        </td>
        <td>{readableDate(formatDate(new Date(comment.created)))}</td>
        <td>{comment ? comment.creator ? comment.creator_name : "" : ""}</td>
        <td className="comments-remove-button">
          <Button
              type="button"
              remove
              onClick={() => removeComment(index)}
              data-testid="remove-part"
              disabled={disabled}
          >
            Remove
          </Button>
        </td>
      </tr>
  );
}

export default Comment;
