import React, {Component, useEffect, useState} from "react";

import { Box } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Form } from "react-bulma-components";
import Collapsible from "react-collapsible";
import useTypeaheadInput from "../../hooks/useTypeaheadInput";
import {createSetDataFreshAction, createSetVehicleFieldAction} from "../../actions/dataActions";
import axios from "axios";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {useDispatch} from "react-redux";
import { Button } from "react-bulma-components";
import KeyPicker from "./KeyPicker";
import NotificationManager from "react-notifications/lib/NotificationManager";

function FeaturesSection(props) {
  const dispatch = useDispatch();
  const [, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [oldTracker, setOldTracker] = useState();

  useEffect(() => {
    setOldTracker(props.data.tracker);
  }, props)

  const updateOldTracker = async (oldTracker) => {
    if(oldTracker) {
      const data = {
        ...oldTracker,
        vehicle: null,
      }
      return axios.put(`/api/vehicletracker/${oldTracker.id}`, data).then(res => console.log('old tracker updated', res));
    }
  }

  const updateNewTracker = async (tracker) => {
    const updatedTracker = {
      ...tracker,
      vehicle: props.data
    }
    return axios.put(`/api/vehicletracker/${tracker.id}`, updatedTracker).then(() => {
      dispatch(createSetVehicleFieldAction({tracker: updatedTracker}));
      setOldTracker(updatedTracker);
    })
  }

  const handleClear = () => {
    updateOldTracker(oldTracker).then(() => setOldTracker(null));
  }

  useEffect(() => {
    setLoading(true);
    axios
        .get(`/api/vehicletracker/searchunused`)
        .then(resp => {
          setSearchData(resp.data.results);
          setLoading(false);
        });
  }, [oldTracker]);

  const handleSelectChange = (e) => {
    const trackerId = Number(e.target.value);
    // find tracker object in search data
    const selectedTracker = searchData.find(tracker => tracker.id === trackerId);
    dispatch(createSetVehicleFieldAction({tracker: selectedTracker}));
    updateOldTracker(oldTracker).then(() => updateNewTracker(selectedTracker).then(() => setOldTracker(selectedTracker)))
  }
    const keyObjects = props.keys.results.reduce((obj, item) => {
      return {
        ...obj,
        [item["id"]]: item
      };
  }, {});


  const onKeyChange = e => {
        const {name, value} = e.target;
        dispatch(createSetVehicleFieldAction({[name]: keyObjects[value]}));
  };

  return (
      <Box>
        <Collapsible
            className="title"
            triggerOpenedClassName="title"
            trigger="Features"
        >
          <Columns>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Current Key Number</Form.Label>
                <Form.Input
                  readOnly={true}
                  disabled={true}
                  value={props.data ? props.data.key ? props.data.key.key_number : "" : ""}
                />
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <KeyPicker
                keys={props.keys}
                onKeyChange={onKeyChange}
                selectedKey={props.data.key}
                clearButton={true}
                dataFresh={props.dataFresh}
                onClear={props.onKeyClear}
                spare={false}
              />
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Has Spare Key</Form.Label>
                <input
                    className="checkbox"
                    type="checkbox"
                    name="has_spare_key"
                    onChange={props.handleCheckbox}
                    checked={props.data.has_spare_key}
                />
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Spare Key Board</Form.Label>
                <Form.Input
                  readOnly={true}
                  disabled={true}
                  value={props.data ? props.data.spare_key ? props.data.spare_key.board : "" : ""}
                />
              </Form.Field>
              <Form.Field>
                <Form.Label>Spare Key Number</Form.Label>
                <Form.Input
                  readOnly={true}
                  disabled={true}
                  value={props.data ? props.data.spare_key ? props.data.spare_key.key_number : "" : ""}
                />
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <KeyPicker
                keys={props.keys}
                onKeyChange={onKeyChange}
                selectedKey={props.data.spare_key}
                clearButton={true}
                dataFresh={props.dataFresh}
                onClear={props.onKeyClear}
                spare={true}
              />
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Slam Lock</Form.Label>
                <input
                    className="checkbox"
                    type="checkbox"
                    name="slam_lock"
                    onChange={props.handleCheckbox}
                    checked={props.data.slam_lock}
                />
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Slam Lock Key Number</Form.Label>
                <Form.Input
                    type="text"
                    name="slam_lock_number"
                    onChange={props.onChange}
                    value={props.data.slam_lock_number}
                />
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Tyre Size</Form.Label>
                <Form.Input
                    type="text"
                    name="tyre_size"
                    onChange={props.onChange}
                    value={props.data.tyre_size}
                />
              </Form.Field>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Radio Code</Form.Label>
                <Form.Input
                    type="text"
                    name="radio_code"
                    onChange={props.onChange}
                    value={props.data.radio_code}
                />
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Service pack</Form.Label>
                <input
                    className="checkbox"
                    type="checkbox"
                    name="service_pack"
                    onChange={props.handleCheckbox}
                    checked={props.data.service_pack}
                />
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Camera</Form.Label>
                <input
                    className="checkbox"
                    type="checkbox"
                    name="camera"
                    onChange={props.handleCheckbox}
                    checked={props.data.camera}
                />
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Log Book</Form.Label>
                <input
                    className="checkbox"
                    type="checkbox"
                    name="log_book"
                    onChange={props.handleCheckbox}
                    checked={props.data.log_book}
                />
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Anti Drill Plate</Form.Label>
                <input
                    className="checkbox"
                    type="checkbox"
                    name="anti_drill_plate"
                    onChange={props.handleCheckbox}
                    checked={props.data.anti_drill_plate}
                />
              </Form.Field>
            </Columns.Column>

          </Columns>
          <Columns>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Window Stickers</Form.Label>
                <input
                    className="checkbox"
                    type="checkbox"
                    name="window_stickers"
                    onChange={props.handleCheckbox}
                    checked={props.data.window_stickers}
                />
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Fire Extinguisher</Form.Label>
                <input
                    className="checkbox"
                    type="checkbox"
                    name="fire_extinguisher"
                    onChange={props.handleCheckbox}
                    checked={props.data.fire_extinguisher}
                />
              </Form.Field>
            </Columns.Column>

            <Columns.Column>
              <Form.Field>
                <Form.Label>Body Serial Number</Form.Label>
                <Form.Input
                    type="text"
                    name="body_serial_number"
                    onChange={props.onChange}
                    value={props.data.body_serial_number}
                />
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Tail Lift Serial number</Form.Label>
                <Form.Input
                    type="text"
                    name="tail_lift_serial_number"
                    onChange={props.onChange}
                    value={props.data.tail_lift_serial_number}
                />
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Tracker IMEI</Form.Label>
                <div>
                  <Form.Control>
                    <Form.Select
                        onChange={handleSelectChange}
                        value={oldTracker ? oldTracker.id : -1}
                        placeholder="Select Tracker"
                    >
                      {!oldTracker && <option disabled={true} value={-1} selected={!oldTracker}>Select Tracker</option>}
                      {oldTracker && <option value={oldTracker?.id} disabled={true}>{oldTracker.imei}</option>}
                      {searchData.map(tracker => <option value={tracker.id}>{tracker.imei}</option>)}
                    </Form.Select>
                  </Form.Control>

                  {oldTracker && oldTracker.imei && <Button
                      tabIndex={-1}
                      onClick={handleClear}
                      className="is-pulled-right"
                      disabled={props.disabled}
                      style={{display: 'inline'}}
                  >
                    Remove Tracker
                  </Button>}
                </div>
              </Form.Field>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Gross Vehicle Weight</Form.Label>
                <Form.Input
                    type="text"
                    name="gross_vehicle_weight"
                    onChange={props.onChange}
                    value={props.data.gross_vehicle_weight}
                />
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Payload</Form.Label>
                <Form.Input
                    type="text"
                    name="payload"
                    onChange={props.onChange}
                    value={props.data.payload}
                />
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Oil Type</Form.Label>
                <Form.Input
                    type="text"
                    name="oil_type"
                    onChange={props.onChange}
                    value={props.data.oil_type}
                />
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Oil Quantity</Form.Label>
                <Form.Input
                    type="text"
                    name="oil_quantity"
                    onChange={props.onChange}
                    value={props.data.oil_quantity}
                />
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Customer's Equipment</Form.Label>
                <Form.Input
                    type="text"
                    name="customer_equipment"
                    onChange={props.onChange}
                    value={props.data.customer_equipment}
                />
              </Form.Field>
            </Columns.Column>
          </Columns>
        </Collapsible>
      </Box>
  );
}

export default FeaturesSection;
