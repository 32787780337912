import React, {useState} from "react";
import Collapsible from "react-collapsible";
import {Button, Columns, Form, Icon} from "react-bulma-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPoundSign} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";

const AsyncTypeahead = asyncContainer(Typeahead);

export default function SaleCustomerDetails({finance, onChange, endpoint}){
    const [isLoading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [, setTypeahead] = useState();


    const handleSearch = query => {
        setLoading(true);
        let url = endpoint + `contacts?contact_type=CC&search=${query}`;
        axios.get(url).then(resp => {
          setSearchData(resp.data.results);
          setLoading(false);
        });
    };

    const handleCustomerSelect = customer => {
        let toUpdate = {
            target: {
                name: "sale_customer",
                value: customer[0]
            }
        }
        onChange(toUpdate);
    }
    const onCheck = e => {
        let toUpdate = {
            target: {
                name: e.target.name,
                value: e.target.checked
            }
        };
        onChange(toUpdate);
    };

    const handleSameCheckBox = e => {
        // Handles setting invoice / delivery address to customer address
        if(e.target.name === "deliver_same_address"){
            if(finance.sale_customer && finance.sale_customer.address_line1){
                onChange({target: {name:"sale_delivery_address", value: (finance.sale_customer.address_line1 + finance.sale_customer.town)}});
            }
        }
        else if(e.target.name === "invoice_same_address"){
            if(finance.sale_customer && finance.sale_customer.address_line1){
                onChange({target: {name:"sale_invoice_address", value: (finance.sale_customer.address_line1 + finance.sale_customer.town)}});
            }
        }
    };

    return (
        <Collapsible
            trigger={"Sale Details"}
            className={"title"}
            triggerOpenedClassName={"title"}
        >
            <Columns>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Sale Customer Name</Form.Label>
                        <Form.Control>
                            <AsyncTypeahead
                              useCache={false}
                              data-testid="sale-customer-search"
                              id="typeahead"
                              labelKey="display_name"
                              minLength={2}
                              name={"sale_customer"}
                              onSearch={handleSearch}
                              onChange={handleCustomerSelect}
                              placeholder={"Search Customer"}
                              options={searchData}
                              ref={typeahead => setTypeahead(typeahead)}
                              className="typeahead"
                              selected={finance.sale_customer && finance.sale_customer.id ? [finance.sale_customer] : []}
                              isLoading={isLoading}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Sale Customer Address</Form.Label>
                        <Form.Control>
                            <Form.Input
                                type={"text"}
                                onChange={onChange}
                                value={finance.sale_customer ? finance.sale_customer.address_line1 + finance.sale_customer.town : ""}
                                name={"sale_customer_address"}
                                disabled={true}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Sale Customer Postcode</Form.Label>
                        <Form.Control>
                            <Form.Input
                                type={"text"}
                                onChange={onChange}
                                value={finance.sale_customer ? finance.sale_customer.postcode : ""}
                                name={"sale_customer_postcode"}
                                disabled={true}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Sale Customer Phone Number</Form.Label>
                        <Form.Control>
                            <Form.Input
                                type={"text"}
                                onChange={onChange}
                                value={finance.sale_customer ? finance.sale_customer.contact_number : ""}
                                name={"sale_customer_phone"}
                                disabled={true}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Sale Customer Email</Form.Label>
                        <Form.Control>
                            <Form.Input
                                onChange={onChange}
                                type={"text"}
                                value={finance.sale_customer ? finance.sale_customer.email : ""}
                                name={"sale_customer_email"}
                                disabled={true}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
                <Columns.Column>
                    <Form.Label>Delivery Address</Form.Label>
                    <Form.Field className={"has-addons"}>
                        <Form.Control>
                            <Form.Input
                                onChange={onChange}
                                type={"text"}
                                value={finance.sale_delivery_address}
                                name={"sale_delivery_address"}
                            />
                        </Form.Control>
                        <Form.Control>
                            <Button
                                color={"info"}
                                name={"deliver_same_address"}
                                onClick={handleSameCheckBox}
                            >
                                Customer
                            </Button>
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
                <Columns.Column>
                    <Form.Label>Sales Invoice Address</Form.Label>
                    <Form.Field className={"has-addons"}>
                        <Form.Control>
                            <Form.Input
                                onChange={onChange}
                                type={"text"}
                                value={finance.sale_invoice_address}
                                name={"sale_invoice_address"}
                            />
                        </Form.Control>
                        <Form.Control>
                            <Button
                                color={"info"}
                                name={"invoice_same_address"}
                                onClick={handleSameCheckBox}
                            >
                                Customer
                            </Button>
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Sold By</Form.Label>
                        <Form.Control>
                            <Form.Input
                                onChange={onChange}
                                type={"text"}
                                value={finance.sold_by}
                                name={"sold_by"}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Balance Method of Payment</Form.Label>
                        <Form.Control>
                            <Form.Input
                                onChange={onChange}
                                type={"text"}
                                value={finance.sale_method_of_payment}
                                name={"sale_method_of_payment"}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Warranty Cost</Form.Label>
                        <Form.Control className={"has-addons"}>
                            <Form.Input
                                onChange={onChange}
                                type={"number"}
                                value={finance.sale_warranty_cost}
                                name={"sale_warranty_cost"}
                            />
                            <Icon align={"left"} className={"small-icon"}>
                                <FontAwesomeIcon icon={faPoundSign}/>
                            </Icon>
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Warranty Term</Form.Label>
                        <Form.Control>
                            <Form.Input
                                onChange={onChange}
                                type={"text"}
                                value={finance.sale_warranty_term}
                                name={"sale_warranty_term"}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Expected Delivery Date</Form.Label>
                        <Form.Control>
                            <Form.Input
                                onChange={onChange}
                                type={"date"}
                                value={finance.sale_expected_delivery_date}
                                name={"sale_expected_delivery_date"}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Trade / Private</Form.Label>
                        <Form.Control>
                            <Form.Input
                                onChange={onChange}
                                type={"text"}
                                value={finance.sale_trade_or_private}
                                name={"sale_trade_or_private"}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Quote / Estimate Made</Form.Label>
                        <Form.Control>
                            <Form.Checkbox
                                onChange={onCheck}
                                checked={finance.sale_quote}
                                name={"sale_quote"}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>V5</Form.Label>
                        <Form.Control>
                            <Form.Checkbox
                                onChange={onCheck}
                                checked={finance.sale_v5}
                                name={"sale_v5"}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>MOT</Form.Label>
                        <Form.Control>
                            <Form.Checkbox
                                onChange={onCheck}
                                checked={finance.sale_mot}
                                name={"sale_mot"}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Service Book</Form.Label>
                        <Form.Control>
                            <Form.Checkbox
                                onChange={onCheck}
                                checked={finance.sale_service_book}
                                name={"sale_service_book"}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Basic Guide</Form.Label>
                        <Form.Control>
                            <Form.Checkbox
                                onChange={onCheck}
                                checked={finance.sale_basic_guide}
                                name={"sale_basic_guide"}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Radio Code</Form.Label>
                        <Form.Control>
                            <Form.Checkbox
                                onChange={onCheck}
                                checked={finance.sale_radio_code}
                                name={"sale_radio_code"}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Main Key</Form.Label>
                        <Form.Control>
                            <Form.Checkbox
                                onChange={onCheck}
                                checked={finance.sale_main_key}
                                name={"sale_main_key"}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Spare Key</Form.Label>
                        <Form.Control>
                            <Form.Checkbox
                                onChange={onCheck}
                                checked={finance.sale_spare_key}
                                name={"sale_spare_key"}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Number of Slam Lock Keys</Form.Label>
                        <Form.Control>
                            <Form.Input
                                name={"sale_slam_lock_key"}
                                onChange={onChange}
                                value={finance.sale_slam_lock_key}
                                type={"number"}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Final Check</Form.Label>
                        <Form.Control>
                            <Form.Checkbox
                                onChange={onCheck}
                                checked={finance.sale_final_check}
                                name={"sale_final_check"}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Settlement</Form.Label>
                        <Form.Control>
                            <Form.Checkbox
                                onChange={onCheck}
                                checked={finance.sale_settlement}
                                name={"sale_settlement"}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Clearance</Form.Label>
                        <Form.Control>
                            <Form.Checkbox
                                onChange={onCheck}
                                checked={finance.sale_clearance}
                                name={"sale_clearance"}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Paid In Full</Form.Label>
                        <Form.Control>
                            <Form.Checkbox
                                onChange={onCheck}
                                checked={finance.sale_paid_in_full}
                                name={"sale_paid_in_full"}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column>
                    <Form.Label>Sold Date</Form.Label>
                    <Form.Input
                        onChange={onChange}
                        name={"sold_date"}
                        value={finance.sold_date}
                        type={"date"}
                    />
                </Columns.Column>
                <Columns.Column/>
                <Columns.Column/>
                <Columns.Column/>
                <Columns.Column/>
            </Columns>
        </Collapsible>
    )




}