import React, {useState} from "react";
import {Box, Columns, Heading} from "react-bulma-components";
import useSelect from "../../hooks/useSelect";
import {useSelector} from "react-redux";
import {getVehicleTrackerFiguresData} from "../../state/selectors";
import {useVehicleTrackerFigureData} from "../../hooks/useVehicleTrackerFigureData";
import Figure from "../Homepage/Figure";
import Spinner from "react-spinner-material";
import {formatDate, getFirstDayOfMonth, getLastDayOfMonth} from "../../utils";



export default function TrackerFigures(props){
    let params = {};

    const [month, monthInput] = useSelect({
        label: "Month",
        options: [
          {name: "January", value: 1},
          {name: "February", value: 2},
          {name: "March", value: 3},
          {name: "April", value: 4},
          {name: "May", value: 5},
          {name: "June", value: 6},
          {name: "July", value: 7},
          {name: "August", value: 8},
          {name: "September", value: 9},
          {name: "October", value: 10},
          {name: "November", value: 11},
          {name: "December", value: 12}
        ],
        initialValue: (new Date().getMonth())+1,
    });

    if(month){
        let year = new Date().getFullYear();
        let date = new Date(month+"-"+"01-"+year);
        let start_date = getFirstDayOfMonth(date);
        let end_date = getLastDayOfMonth(date);

        params.start_date = formatDate(start_date);
        params.end_date = formatDate(end_date);
    }

    const trackerFiguresLoaded = useVehicleTrackerFigureData(params, props.dataFresh, false);
    const trackerFigures = useSelector(getVehicleTrackerFiguresData);

    if (!trackerFiguresLoaded) {
        return (
          <Box>
            <Spinner
              className="spinner-centre"
              size={120}
              spinnerColor={"#3273dc"}
              spinnerWidth={2}
            />
          </Box>
        );
    }
    let activeCount;
    let trackerCostFigures = trackerFigures.reduce((current, newVal) => {
        if(!Object.entries(newVal)[0].includes("Active")){
            return current.concat(newVal);
        }
        else{
            activeCount = newVal;
            return current;
        }
    }, []);
    return (
        <Box>
            <Heading size={4}>Tracker Type Cost Summary</Heading>
            <Columns>
                {monthInput}
                <Columns.Column>
                    {"Active Tracker Count: "+activeCount['Active']}
                </Columns.Column>
            </Columns>
            <Columns>
                {trackerCostFigures.map((value) => (
                    <Figure
                        label={value.name+" Cost"}
                        value={value.total_cost}
                        loaded={trackerFiguresLoaded}
                    />
                ))}
            </Columns>
            <Columns>
                {trackerCostFigures.map((value) => (
                    <Columns.Column>
                      {value.name} Count :
                      {trackerFiguresLoaded && value.count != null ?
                      value.count
                      :
                      "Loading..."
                      }
                    </Columns.Column>
                ))}
            </Columns>
        </Box>
    )


}