import React from "react";
import {Columns, Table, Form} from "react-bulma-components";
import {readableDate} from "../../utils";
import vanFront from "./images/van front.png"
import vanRear from "./images/van rear.png"
import vanSideLeft from "./images/van side left.png"
import vanSideRight from "./images/van side right.png"
class PrintCustomerCollectionSheet extends React.Component{




    render () {
        let vehicle = this.props.vehicle;
        let finance = this.props.finance;
        let service = this.props.service;
        let today = new Date()
        today = today.getFullYear()+"-"+(today.getMonth()+1)+"-"+today.getDate();
        return (
            <div>
                <Columns>
                    <Columns.Column>
                       <Table bordered size={"fullwidth"}>
                           <tbody>
                                <tr>
                                   <th colSpan={8} className={"table-header-bold border-black"}>CUSTOMER COLLECTION / DELIVERY SHEET</th>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>DATE :</th>
                                    <td className={"wide-column border-black"} data-testId={"pro-forma-date"}>{readableDate(today)}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>CUSTOMER NAME :</th>
                                    <td colSpan={5} className={"wide-column border-black"} data-testID={"sale-customer2"}>{finance.sale_customer ? finance.sale_customer.display_name : ""}</td>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>ADDRESS :</th>
                                    <td colSpan={5} className={"wide-column border-black"} data-testId={"customer-address"}>{finance.sale_customer_address}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>POST CODE: </th>
                                    <td className={"wide-column border-black"} data-testId={"sale-postcode"}>{finance.sale_customer_postcode}</td>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>PHONE NUMBER :</th>
                                    <td colSpan={3} className={"wide-column border-black"} data-testId={"phone"}>{finance.sale_customer_phone}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>EMAIL :</th>
                                    <td colSpan={4} className={"wide-column border-black"} data-testId={"email"}>{finance.sale_customer_email}</td>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>REGISTRATION :</th>
                                    <td className={"wide-column border-black"} data-testId={"vehicle-reg2"}>{vehicle.registration}</td>
                                    <th colSpan={2} className={"table-header-bold fill-grey border-black small-text"}>MAKE/MODEL</th>
                                    <td colSpan={4} className={"wide-column border-black"} data-testId={"make-model"}>{(vehicle.make ? vehicle.make.name : " ") + " / " + (vehicle.model ? vehicle.model.name : "")}</td>
                                </tr>
                                <tr>
                                    <th colSpan={2} className={"table-header-bold fill-grey border-black small-text"}>CHASSIS NO. :</th>
                                    <td colSpan={6} className={"wide-column border-black"} data-testId={"vehicle-vin2"}>{vehicle.vin_number}</td>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>MILEAGE :</th>
                                    <td colSpan={2} className={"wide-column border-black"} data-testId={"vehicle-mileage2"}>{vehicle.mileage}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>DOFR :</th>
                                    <td colSpan={2} className={"wide-column border-black"} data-testId={"vehicle-dofr2"}>{readableDate(vehicle.first_registration_date)}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>FLEET NO. :</th>
                                    <td colSpan={2} className={"wide-column border-black"} data-testId={"fleet-number"}>{vehicle.fleet_number ? vehicle.fleet_number : ""}</td>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>ADVERTISED PRICE :</th>
                                    <td colSpan={2} className={"wide-column border-black"} data-testId={"advertised-price"}>£{finance.external_list_price}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>BOOK :</th>
                                    <td colSpan={2} className={"wide-column border-black"} data-testId={"sale-book"}>£{finance.estimated_value.toFixed(2)}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>FINANCE :</th>
                                    <td colSpan={2} className={"wide-column border-black"} data-testId={"finance-remaining"}>£{finance.remaining_balance}</td>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>SOLD BY :</th>
                                    <td colSpan={2} className={"wide-column border-black"} data-testId={"sold-by"}>{finance.sold_by}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>SOLD PRICE :</th>
                                    <td colSpan={2} className={"wide-column border-black"} data-testId={"sold-price2"}>£{finance.sold_price}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>SOLD DATE :</th>
                                    <td colSpan={2} className={"wide-column border-black"} data-testId={"sold-date"}>{readableDate(finance.sold_date)}</td>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>SALES INVOICE NO. :</th>
                                    <td colSpan={2} className={"wide-column border-black"} data-testId={"sale-invoice"}>{vehicle.fleet_number}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>DEPOSIT PAID :</th>
                                    <td colSpan={2} className={"wide-column border-black"} data-testId={"sale-deposit2"}>£{finance.sale_deposit}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>BALANCE METHOD OF PAYMENT :</th>
                                    <td colSpan={2} className={"wide-column border-black"} data-testId={"method-payment"}>{finance.sale_method_of_payment}</td>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>EXTRA WARRANTY COST :</th>
                                    <td colSpan={2} className={"wide-column border-black"} data-testId={"warranty-cost2"}>£{finance.sale_warranty_cost}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>WARRANTY TERM :</th>
                                    <td colSpan={2} className={"wide-column border-black"} data-testId={"warranty-term"}>{finance.sale_warranty_term}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}> EXPECTED DELIVERY / COLLECTION DATE :</th>
                                    <td colSpan={2} className={"wide-column border-black"} data-testId={"sale-expected-delivery-date"}>{finance.sale_expected_delivery_date}</td>
                                </tr>
                                <tr>
                                    <th colSpan={5} className={"table-header-bold fill-grey border-black small-text"}>COMMENTS</th>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>TRADER / PRIVATE :</th>
                                    <td colSpan={2} className={"wide-column border-black"} data-testId={"trade-private"}>{finance.sale_trade_or_private}</td>
                                </tr>
                                <tr>
                                    <td colSpan={8} className={"wide-column out-border-top-black out-border-middle-black"}>{finance.sale_comments}</td>
                                </tr>
                                <tr>
                                    <td colSpan={8} className={"wide-column out-border-middle-black"}></td>
                                </tr>
                                <tr>
                                    <td colSpan={8} className={"wide-column out-border-middle-black"}></td>
                                </tr>
                                <tr>
                                <td colSpan={8} className={"wide-column out-border-bottom-black out-border-middle-black"}></td>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>DELIVERY DRIVER :</th>
                                    <td colSpan={3} className={"wide-column border-black"}></td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>REG. OF RECOVERY TRUCK USED :</th>
                                    <td colSpan={3} className={"wide-column border-black"}></td>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>FUEL</th>
                                    <td className={"border-black"}></td>
                                    <td className={"border-black"}></td>
                                    <td className={"border-black"}></td>
                                    <th colSpan={2} className={"table-header-bold fill-grey border-black small-text"}>SPARE WHEEL CHECK :</th>
                                    <td colSpan={2} className={"border-black"}></td>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>ADBLUE</th>
                                    <td className={"border-black"}></td>
                                    <td className={"border-black"}></td>
                                    <td className={"border-black"}></td>
                                    <th colSpan={2} className={"table-header-bold fill-grey border-black small-text"}>TOOLKIT & JACK CHECK</th>
                                    <td colSpan={2} className={"border-black"}></td>
                                </tr>
                                <tr>
                                    <th colSpan={6} className={"table-header-bold fill-grey border-black"}>REPORTED DAMAGE TO VEHICLE</th>
                                    <th colSpan={2} className={"table-header-bold fill-black border-black small-text"}>DRIVERS TIMES</th>
                                </tr>
                                <tr>
                                    <td colSpan={1} className={"out-border-top-black out-border-middle-left-black"}>
                                        <img className={"small-img"} src={vanFront}/>
                                    </td>
                                    <td colSpan={2} className={"out-border-top-black out-border-bottom-white out-border-middle-white"}>
                                        <img className={"small-img"} src={vanSideLeft}/>
                                    </td>
                                    <td colSpan={2} className={"out-border-top-black out-border-bottom-white out-border-middle-white"}>
                                        <img className={"small-img"} src={vanSideRight}/>
                                    </td>
                                    <td colSpan={1} className={"out-border-top-black out-border-middle-right-black"}>
                                        <img className={"small-img"} src={vanRear}/>
                                    </td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>START TIME
                                    </th>
                                    <td className={"wide-column border-black"}></td>
                                </tr>
                                <tr>
                                    <td colSpan={6} className={"wide-column out-border-middle-black"}></td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>ARRIVE TIME</th>
                                    <td className={"wide-column border-black"}></td>
                                </tr>
                                <tr>
                                    <td colSpan={6} className={"wide-column out-border-middle-black"}></td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>FINISH TIME</th>
                                    <td colSpan={1} className={"wide-column border-black"}></td>
                                </tr>
                                <tr>
                                    <td colSpan={6} className={"wide-column out-border-middle-black"}></td>
                                    <th colSpan={2} className={"table-header-bold fill-black border-black small-text"}>SENT WITH VEHICLE</th>
                                </tr>
                                <tr>
                                    <td colSpan={6} className={"wide-column out-border-middle-black out-border-bottom-black"}></td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>QUOTE / ESTIMATE</th>
                                    <td className={"wide-column border-black"}>
                                        <Form.Checkbox
                                            checked={finance.sale_quote}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={6} className={"table-header-bold fill-black border-black small-text"}>SERVICE RECORD</th>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>V5</th>
                                    <td className={"wide-column border-black"}>
                                        <Form.Checkbox
                                            checked={finance.sale_v5}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>SERVICE DATE</th>
                                    <td colSpan={2} className={"wide-column border-black"}>{service.length>0 ? readableDate(service[0].date) : ""}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>MILEAGE</th>
                                    <td colSpan={2} className={"wide-column border-black"}>{service.length>0 ? service[0].mileage : ""}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>MOT</th>
                                    <td className={"wide-column border-black"}>
                                        <Form.Checkbox
                                            checked={finance.sale_mot}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>SERVICE DATE</th>
                                    <td colSpan={2} className={"wide-column border-black"}>{service.length>1 ? readableDate(service[1].date) : ""}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>MILEAGE</th>
                                    <td colSpan={2} className={"wide-column border-black"}>{service.length>1 ? service[1].mileage : ""}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>SERVICE BOOK</th>
                                    <td className={"wide-column border-black"}>
                                        <Form.Checkbox
                                            checked={finance.sale_service_book}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>SERVICE DATE</th>
                                    <td colSpan={2} className={"wide-column border-black"}>{service.length>2 ? readableDate(service[2].date) : ""}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>MILEAGE</th>
                                    <td colSpan={2} className={"wide-column border-black"}>{service.length>2 ? service[2].mileage : ""}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>BASIC GUIDE</th>
                                    <td className={"wide-column border-black"}>
                                        <Form.Checkbox
                                            checked={finance.sale_basic_guide}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>SERVICE DATE</th>
                                    <td colSpan={2} className={"wide-column border-black"}>{service.length>3 ? readableDate(service[3].date) : ""}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>MILEAGE</th>
                                    <td colSpan={2} className={"wide-column border-black"}>{service.length>3 ? service[3].mileage : ""}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>RADIO CODE</th>
                                    <td className={"wide-column border-black"}>
                                        <Form.Checkbox
                                            checked={finance.sale_radio_code}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>SERVICE DATE</th>
                                    <td colSpan={2} className={"wide-column border-black"}>{service.length>4 ? readableDate(service[4].date) : ""}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>MILEAGE</th>
                                    <td colSpan={2} className={"wide-column border-black"}>{service.length>4 ? service[4].mileage : ""}</td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>MAIN KEY</th>
                                    <td className={"wide-column border-black"}>
                                        <Form.Checkbox
                                            checked={finance.sale_main_key}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={6} className={"table-header-bold fill-black border-black small-text"}>SENT WITH VEHICLE</th>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>SPARE KEY</th>
                                    <td className={"wide-column border-black"}>
                                        <Form.Checkbox
                                            checked={finance.sale_spare_key}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>SETTLEMENT</th>
                                    <td className={"wide-column border-black"}>
                                        <Form.Checkbox
                                            checked={finance.sale_settlement}
                                        />
                                    </td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>CLEARANCE</th>
                                    <td className={"wide-column border-black"}>
                                        <Form.Checkbox
                                            checked={finance.sale_clearance}
                                        />
                                    </td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>PAID IN FULL</th>
                                    <td className={"wide-column border-black"}>
                                        <Form.Checkbox
                                            checked={finance.sale_paid_in_full}
                                        />
                                    </td>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>NUMBER OF SLAM LOCK KEY</th>
                                    <td className={"wide-column border-black"}>{finance.sale_slam_lock_key}</td>
                                </tr>
                                <tr>
                                    <th colSpan={2} className={"border-black small-text"}>SIGNED :</th>
                                    <th colSpan={2} className={"border-black small-text"}>PRINT NAME :</th>
                                    <th colSpan={2} className={"border-black small-text"}>DATE :</th>
                                    <th className={"table-header-bold fill-grey border-black small-text"}>FINAL CHECK OVER</th>
                                    <td className={"wide-column border-black"}>
                                        <Form.Checkbox
                                            checked={finance.sale_final_check}
                                        />
                                    </td>
                                </tr>
                           </tbody>
                       </Table>
                    </Columns.Column>
                </Columns>
            </div>
        )
    }
}

export default PrintCustomerCollectionSheet;